import React from "react"

const MetierConsultantContent = () => (
  <div className="p-5">
    <h3 className="font-weight-bold text-black mb-4">
      Le Consultant Cleeven est tourné vers l’avenir, il a l’ambition et la curiosité d’évoluer dans l’entreprise
      depuis son coeur.
    </h3>
    <ul>
      <li>Vous acquérez ainsi rapidement les clés de réussite techniques et humaines.</li>
      <li>Vous véhiculez l’excellence de Cleeven au quotidien et êtes au centre de l’action. Cela fait de vous un
        acteur
        majeur dans l’évolution de votre entreprise.
      </li>
      <li>Vous pouvez compter sur la disponibilité, la compétence, et le professionnalisme de votre Manager Cleeven.</li>
      <li>Vous bénéficiez également de son soutien et de son expertise au quotidien, en matière de communication, de
        management, de développement du potentiel et de culture technique comme d’entreprise.
        <ul>
          <li>Nous sommes là pour vous faire grandir, vous booster, vous challenger, vous accompagner et vous
            révéler.
          </li>
          <li>Notre volonté est de vous faire réussir.</li>
          <li>Devenez ce que vous êtes et obtenez ce que vous voulez de vous-même.</li>
        </ul>
      </li>
    </ul>
  </div>
)

export default MetierConsultantContent