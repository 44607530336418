import Hero from "../components/hero"
import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import SectionTitle from "../components/sectionTitle"
import Section from "../components/section"

import MetierConsultantContent from "../components/consultant/metierConsultantContent"
import SuiviProjet from "../components/consultant/suiviProjetContent"

import metierConsultantVideoImage from "../images/vignette-consultant-video.jpg";
import iconConsultant from "../images/icon-consultant.png";
import ConsultantSuiviImage from "../images/consultant-suivi.jpg";
import SocialButtons from "../components/socialButtons"

const ConsultantPage = () => (
  <Layout>
    <SocialButtons/>
    <SEO title="Être consultant"/>
    <div id="consultant-content" className="row">
      <Hero text="Devenez ce que vous êtes" id="consultant"/>
      <SectionTitle text="Être consultant Cleeven" iconImg={iconConsultant} left/>
      <Section title="Métier du consultant" inversed videoId="wFZq9Jy3O_w" bgColor="#ffffff" imageUrl={metierConsultantVideoImage} contain>
        <MetierConsultantContent/>
      </Section>
      <Section title="Suivi de projet" imageUrl={ConsultantSuiviImage} bgColor="#f3f3f3">
        <SuiviProjet/>
      </Section>
    </div>
  </Layout>
)

export default ConsultantPage