import React from "react"

const SuiviProjet = () => (
  <div className="p-5">
    <h3 className="font-weight-bold text-black mb-4">
      Le document de suivi de projet est essentiel pour la réussite de votre projet et votre épanouissement au sein de Cleeven.
    </h3>
    <ul>
      <li>Il précise l’avancement de votre projet et les compétences mises en œuvre et reflète votre implication et votre professionnalisme. </li>
      <li>Il permet de mesurer le chemin parcouru, d’optimiser votre organisation, d’identifier clairement votre objectif à court et moyen terme et donc de mener votre projet au succès.</li>
      <li>Il garantit une vision claire de votre satisfaction et de celle de nos clients.</li>
      <li>Ce suivi de projet est réalisé mensuellement et permet de faire le point avec l’interlocuteur technique client. Il doit donc être complété et considéré avec le plus grand sérieux et renvoyé à votre responsable hiérarchique avant le 25 de chaque mois.</li>
    </ul>
  </div>
)

export default SuiviProjet